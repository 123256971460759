



































































import { addConfig, getEntity, metadataList } from '@/api/fileTemplate';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Search, { ConditionOption, Expression } from './search.vue';

@Component({
  components: { Search },
})
export default class CheckSetting extends Vue {
  id = '';
  sections: Record<string, any> = {};
  symbols = [
    {
      label: '包含',
      value: 'contains',
    },
    {
      label: '等于',
      value: 'eq',
    },
    {
      label: '大于',
      value: 'gt',
    },
    {
      label: '小于',
      value: 'lt',
    },
  ];
  names = {
    DOCUMENT_RETRIEVAL: '关键词：',
    HEADER_FOOTER: '表眉表脚：',
  };
  radioValue = '';
  metadata: any = [];
  sheetType = '';
  normal: Record<string, Expression[]> = {};
  special: Expression[] = [];

  @Watch('$route', { immediate: true })
  watchRoute(route: Route): void {
    this.id = route.query.id as string;
    this.getEntities();
    this.getMetadataList();
  }

  getEntities(): void {
    getEntity(this.id).then((res) => {
      const { fileSectionInstanceDTOList = [] } = res.result;
      this.sections = fileSectionInstanceDTOList
        .filter((item) => item.sectionType !== 'TABLES')
        .reduce((prev, curr) => {
          if (!(curr.sectionType in prev)) {
            prev[curr.sectionType] = [];
          }
          prev[curr.sectionType].push({
            ...curr,
            label: curr.sectionName,
            value: curr.sectionCode,
          });
          return prev;
        }, {});
      this.radioValue = this.sections['SPECIAL_CONDITIONS'][0].value;
    });
  }

  transformSubset(properties: any, arr: any): void {
    for (const item of properties) {
      arr.push(item);
      if (item.subset) {
        this.transformSubset(item.subset, arr);
      }
    }
  }

  getMetadataList(): void {
    metadataList().then((res) => {
      this.metadata = res.result.map((item) => {
        const arr = [];
        this.transformSubset(item.property, arr);
        return {
          ...item,
          property: arr,
        };
      });
      this.sheetType = this.metadata[0].code;
    });
  }

  columns = [
    {
      title: '属性名称',
      dataIndex: 'name',
    },
    {
      title: '属性Key',
      dataIndex: 'code',
    },
    {
      title: '属性别名',
      dataIndex: 'alias',
      scopedSlots: { customRender: 'alias' },
    },
    {
      title: '是否启用',
      dataIndex: 'status',
    },
    {
      title: '描述',
      dataIndex: 'describe',
    },
  ];

  data: any = [];

  @Watch('sheetType')
  watchType(val: string): void {
    this.data = this.metadata
      .find((item) => item.code === val)
      .property.map((item) => ({
        ...item,
        alias: '',
      }));
  }

  get specialNames(): ConditionOption[] {
    return this.data.map((item) => ({
      label: item.name,
      value: item.code,
    }));
  }

  changeValue(val: Expression[]): void {
    const values = val.reduce((prev, curr) => {
      if (!(curr.conditionName in prev)) {
        prev[curr.conditionName] = [];
      }
      prev[curr.conditionName].push(curr);
      return prev;
    }, {});
    this.normal = { ...this.normal, ...values };
    console.log(this.normal);
  }

  specialChange(val: Expression[]): void {
    this.special = val;
  }

  save(): void {
    const sections = Object.values(this.sections).flat(1);
    console.log(sections);
    const formState: {
      entityId: string;
      fileSectionInstanceDTOList: any[];
      filesSectionMetadataDTOList: Record<string, string>[];
    } = {
      entityId: this.id,
      fileSectionInstanceDTOList: [],
      filesSectionMetadataDTOList: [],
    };
    const noral = Object.entries(this.normal).map((entries) => {
      const [k, v] = entries;
      const { sectionType, sectionName, sectionCode, status, sort } =
        sections.find((item) => item.sectionCode === k);
      return {
        sectionType,
        sectionName,
        sectionCode,
        status,
        sort,
        keywordManageDTO: {
          keywordValueDTOList: v.map((item, i) => ({
            keyword: item.conditionLabel,
            relationalOperator: item.conditionSymbol,
            logicOperator: item.conditionOperator,
            extend1: item.conditionName,
            extend2: item.conditionValue,
            sort: i,
          })),
        },
      };
    });
    const { sectionType, sectionName, sectionCode, status, sort } =
      sections.find((item) => item.sectionCode === this.radioValue);
    const special = {
      sectionType,
      sectionName,
      sectionCode,
      status,
      sort,
      keywordManageDTO: {
        keywordValueDTOList: this.special.map((item, i) => ({
          keyword: item.conditionLabel,
          relationalOperator: item.conditionSymbol,
          logicOperator: item.conditionOperator,
          extend1: item.conditionName,
          extend2: item.conditionValue,
          sort: i,
        })),
      },
    };
    formState.fileSectionInstanceDTOList = [...noral, special];
    const item = this.metadata.find((item) => item.code === this.sheetType);
    const metadata = this.data.map((m) => ({
      perprotyName: m.name,
      dataObjPropertyCode: m.code,
      perprotyAlias: m.alias || m.name,
      dataObjCode: item.code,
      entitySource: item.name,
    }));
    formState.filesSectionMetadataDTOList = metadata;
    console.log(formState);
    addConfig(formState).then(() => {
      this.$message.success('操作成功');
      this.$router.push({
        path: '/setting/excel/sheets',
        query: { id: this.id },
      });
    });
  }
}
